<template>
    <div class="cultural">
        <div class="time-item">
            <label>时间筛选：</label>
            <el-date-picker
                    v-model="startTime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
            </el-date-picker>
            <el-radio-group class="radio-group" v-model="chooseTime" size="small" :border="false">
                <el-radio-button label="近一周"></el-radio-button>
                <el-radio-button label="近一个月"></el-radio-button>
            </el-radio-group>
        </div>
        <div class="platform-filtering">
            <label>平台筛选：</label>
            <el-select v-model="platformValue" placeholder="请选择" @change="selectPlatform($event)">
                <el-option
                        v-for="item in optionsPlatform"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <el-table :data="platformTable" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="title" label="标题" align="left" width="400"></el-table-column>
            <el-table-column prop="publishing_platform" label="发布平台" align="center"></el-table-column>
            <el-table-column prop="reading_volume" label="阅读量" align="center"></el-table-column>
            <el-table-column prop="comments" label="评论量" align="center"></el-table-column>
            <el-table-column prop="release_time" label="发布时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <span class="view">查看</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "CulturalCreationDetails",
        data(){
            return{
                startTime:'',
                chooseTime: '近一周',
                platformValue:[],
                optionsPlatform:[
                    {
                        id:1,
                        name: '头条号'
                    },
                    {
                        id:2,
                        name: '知乎'
                    }
                ],
                platformTable:[
                    {
                        title:'4步就搞定的嫩牛肉做法！简单粗暴，懒人必备！',
                        publishing_platform:'头条号',
                        reading_volume:'252',
                        comments:'180',
                        release_time:'2020-06-28',
                    }
                ],
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },

            }
        },
        methods:{
            // 平台选择
            selectPlatform(){

            },
            studentCurrentChange(){
                this.studentPages.currentPageNum = val;
            },
        }
    }
</script>

<style scoped lang="scss">
    .cultural{
        width: calc(100% - 280px);
        background: #fff;
        margin: 20px 0 10px ;
        display: flex;
        flex-direction: column;
        .time-item{
            padding: 20px 0 0 20px;
        }
        .radio-group{
            margin-left: 10px;

            ::v-deep.el-radio-button__orig-radio:checked+.el-radio-button__inner {
                background-color: #0824ce;
                border-color: #0824ce;
            }
            ::v-deep.el-radio-button--small .el-radio-button__inner{
                padding: 12px 15px;
            }
        }
        .platform-filtering{
            padding: 20px;
        }
        ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
            background-color: transparent;
        }
        ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
            padding: 10px 9px;
        }
        .view{
            color: #2337E5;
        }
    }

</style>